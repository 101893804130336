import React from "react"
import Img from "gatsby-image"
import { Card, Button } from "react-bootstrap"
import { Link } from "gatsby"
import { LINKS } from "../../../data/pageLinks.data"
import ApButton from "../../Global/Common/ApButton/ApButton";
import { FeatureCardStyles } from "./FeatureCardStyles"


const data = {
  0: {
    title: "Contact us via mail",
    body: `If you wish to write us an email instead please use.`,
    callToAction: `sales@apexx.global`,
    link: "mailto:sales@apexx.global"
  },
  1: {
    title: "Tech Support",
    body: `We're here to answer your technical questions whenever you need.`,
    callToAction: `Go to support`,
    link: LINKS.support
  },
}

const FeatureCard = ({ img, idx }) => {
  return (
    <FeatureCardStyles>
    <Card className="bg-transparent border-0 w-100 d-flex d-lg-block flex-column align-items-left">
      <Img
        className="card-img-top"
        fluid={img.node.childImageSharp.fluid}
      />
      <Card.Body className="pl-0">
        <Card.Title className="poppins primary-blue-text">
          {data[idx].title}
        </Card.Title>
        <Card.Text className="poppins primary-blue-text">
          {data[idx].body}
        </Card.Text>
        <Link to={data[idx].link} className="secondary-blue-2-text pl-0">
        {/* <ApButton variant="link" className="secondary-blue-2-text pl-0"> */}
          - {data[idx].callToAction}
        {/* </ApButton> */}
        </Link>
      </Card.Body>
    </Card>
    </FeatureCardStyles>
  )
}

export default FeatureCard
