import React, { useEffect } from 'react';
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap"
import SideBar from "../SideBar/SideBar"
import { LINKS } from "../../../data/pageLinks.data"
import { Link } from "gatsby"

const HubspotSalesForm = () => {
  useEffect(() => {
    // Load the HubSpot script dynamically
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "141862351",
          formId: "a0347588-29fc-4574-bcc6-c3654d31f1a1",
          target: '#hubspot-form'
        });
      }
    };
    document.body.appendChild(script);

    // Clean up on component unmount
    return () => {
      document.body.removeChild(script);
    };
  });

  return (
    <div className="m-5">
        <Container>
            <Row className="pb-lg-5">
                <Col>
                  <h2 className="h1 contact-form-heading py-4 secondary-blue-2-text">
                    Contact our team
                  </h2>
                  <p className="pb-4 pb-lg-5">
                    Please fill in the form provided on this page and we’ll get back
                    to you as soon as possible. For press releases, company
                    information, media resources and more, you can visit our dedicated{" "}
                    <Link className="secondary-blue-2-text" to={LINKS.pressAndMedia}>press page</Link>.
                  </p>
                  <div id="hubspot-form"></div>
                </Col>
                <Col md={{ offset: 2, span: 3 }} className="d-none d-lg-block">
                    <SideBar />
                </Col>
            </Row>
        </Container>
    </div>
    );
};

export default HubspotSalesForm;
