import React from "react"
import { Col, Container, Jumbotron, Row } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { HeroWrapper } from "./HeroSectionStyles"
import Img from "gatsby-image"

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "contactUs" }
          name: { in: ["hero", "Mail", "Support"] }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges

  return (
    <HeroWrapper className="position-relative">
      <Jumbotron className="secondary-blue-3-background rounded-0 mb-0 header-jumbotron text-left p-5">
        <h1 style={{visibility:"hidden"}}>Contact Us</h1>
        <Container className="pb-5 hero-text">
          <h2 className="h1 blue-aqua font-weight-bold py-lg-3 nunito">We’re here to help</h2>
          <p className="text-white">
            Discuss your connected payment needs with one of our global
            advisors.
          </p>
        </Container>
      </Jumbotron>
      <div className="d-none d-lg-block side-image position-absolute mt-2 contact-us-image">
        <Img fluid={images[2].node.childImageSharp.fluid} />
      </div>
    </HeroWrapper>
  )
}

export default HeroSection
