import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import FeatureCard from "../FeatureCard/FeatureCard"
import { useStaticQuery, graphql } from "gatsby"
import { FeatureCardWrapper } from "./FeatureCardSectionStyles"

const FeatureCardSection = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "contactUs" }
          name: { in: ["hero", "Mail", "Support"] }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges

  return (
    <FeatureCardWrapper className="position-relative d-lg-none">
      <div className="bg-gray p-5 text-left">
        <Container className="py-3 mx-0 mx-auto">
          <Row className="features-section">
            {images.slice(0, 2).map((img, idx) => (
              <Col key={img.node.id} xs={12} sm={6} className="mb-4 mb-sm-0 pb-2 pb-sm-0 col-item">
                <FeatureCard img={img} idx={idx} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </FeatureCardWrapper>
  )
}

export default FeatureCardSection
