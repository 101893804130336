import React from "react"
import HubspotSalesForm from "../components/ContactUsPage/ContactForm/HubspotSalesForm"
import HeroSection from "../components/ContactUsPage/HeroSection/HeroSection"
import FeatureCardSection from "../components/ContactUsPage/FeatureCardSection/FeatureCardSection"
import Layout from "../components/Global/Layout/Layout"
import SEO from "../components/Global/SEO/seo"

const ContactUs = ({location}) => {
  const params = new URLSearchParams(location.search);
  let success = params.get('success');
  return (
    <Layout>
      <SEO page="contact-us" />
      <HeroSection />
      <HubspotSalesForm />
      <FeatureCardSection />
    </Layout>
  )
}

export default ContactUs
