import styled from "styled-components"

export const FeatureCardWrapper = styled.section`
  .header-jumbotron {
    @media (min-width: 992px) {
      font-size: 1.2rem;
      .h1 {
        font-size: 3rem !important;
      }
    }
  }
  .rounded-stock-img {
    border-radius: 12px;
    margin-bottom: -120px;
  }
  .dots {
    position: absolute;
    right: 40px;
    bottom: 20px;
    .dot {
      height: 25px;
      width: 25px;
      margin-left: 10px;
      background-color: #d8d8d8;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .side-image {
    top: -10px;
    right: 0px;
    width: 30%;
  }
  .features-section{
    img {

    }
    .col-item{
      &:last-child{
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
`
